








import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: { BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: "RFID Cards",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "tenants",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "name",
                              orderType: "asc",
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "email",
                              orderType: "asc",
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              value: {
                attrs: {
                  label: "Tag",
                },
                rules: {
                  match: {},
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Status",
                  items: [
                    { text: "Active", value: true },
                    { text: "Inactive", value: false },
                  ],
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            value: {
              text: "Tag",
              sortable: true,
            },
            description: {
              text: "Description",
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            status: {
              text: "Status",
              options: {
                label(value) {
                  return (value && "Active") || "Inactive";
                },
                attrs(value) {
                  return {
                    color: value ? "green" : "red",
                  };
                },
              },
            },
            user: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
              text: "User",
              options: {
                subProp: "user.email",
                label: true,
                sortBy: "userId",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              ext: {
                condition() {
                  return appService.activeTenantValid();
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "value",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            const activeTenant = appService.getActiveTenant();
            if (activeTenant) {
              options.filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: activeTenant._id,
              });
            }
            options.extraParams.currentTimeZoneOffset =
              new Date().getTimezoneOffset() * -1 * 60 * 1000;
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "tags",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call(
                "tags",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "tags",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("tags", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              value: {
                attrs: {
                  label: "Tag",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              status: {
                type: "boolean",
                attrs: {
                  label: "Active",
                },
                ext: {
                  defaultValue: true,
                },
              },
              userId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  "item-value": "_id",
                  "item-text": (item) => `${item.email} (${item.username})`,
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const filters = [
                          {
                            key: "_id",
                            operator: "equal_to",
                            value,
                          },
                        ];
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "name",
                              orderType: "asc",
                              filters,
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["value", "description", "status", "userId"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["description", "status", "userId"],
              },
            },
            delete: {
              confirmDisplayField: "value",
            },
          },
        },
      },
    };
  },
});
